<template>
<div>
<div class="paymerang-logo"></div><br>
  <div id="add-invoice" class="container main-pane">
    <div class="col-12">
        <button @click="returnHome">Return</button>
    </div><br>
    <div id="name-row" class="row">
      <div id="add-payee-personal-info">
        <select id="payee-list" placeholder="Payee Name" /><br>
      </div>
      <div id="address-row" class="col-12">
        <input id="add-payor-name" class="payee-data" placeholder="Payor Name"><br>
        <input id="add-payor-id" class="payee-data" placeholder="Payor ID"><br>
        <input id="add-invoice-number" class="payee-data" placeholder="Invoice Number"><br>
        <input id="add-description" class="payee-data" placeholder="Description"><br>
        <input id="add-amount" class="payee-data" placeholder="Amount">
    </div>
    <div id="back-button-div" class="col-12">
      <button @click="collateNewInvoice">Add Invoice</button>
    </div>
    </div><br>
      <div class="col-12">
              <button @click="returnHome">Return</button>
    </div>
  </div>

</div>
</template>

<script>
export default {
  name: 'AddInvoice',
  props: [
   "passedData",
  ],
  data() {
    return {
      newInvoice : 
      {
        "PayorName": "",
        "PayorId": 0,
        "InvoiceNo": 0,
        "Description": "",
        "Amount": "",
      },
      payeeName : "",
    }
  },
  mounted(){
    this.buildPayeeList();
  },
  methods: {
    collateNewInvoice() {
      if (
        document.querySelector("#add-payor-name").value != "" &&
        document.querySelector("#add-payor-id").value != "" &&
        document.querySelector("#add-invoice-number").value != "" &&
        document.querySelector("#add-description").value != "" &&
        document.querySelector("#add-amount").value != ""
      ){
         this.payeeName = document.querySelector("#payee-list").value
        this.newInvoice["PayorName"] = document.querySelector("#add-payor-name").value
        this.newInvoice["PayorId"] = document.querySelector("#add-payor-id").value
        this.newInvoice["InvoiceNo"] = document.querySelector("#add-invoice-number").value
        this.newInvoice["Description"] = document.querySelector("#add-description").value
        this.newInvoice["Amount"] = document.querySelector("#add-amount").value
        this.$emit('addInvoice', this.newInvoice, this.payeeName);
      }
      else {
        alert("All boxes must be filled out accurately to add an Invoice.")
      }
     

    },
    buildPayeeList() {
        var select = document.querySelector("#payee-list");
        select.innerHTML = "";
        var options = []; 

        for (var k=0; k<this.passedData.length; k++){
            options.push(this.passedData[k]["Payee"]["Name"]);
        }
        for (var i=0; i<options.length; i++){
            var opt = options[i];
            var el = document.createElement("option");
            el.textContent = opt;
            el.value = opt;
            select.appendChild(el);
        }
    },
    returnHome() {
      this.$emit('openPage', 'payees');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
select{
    margin-bottom: 20px;
    width: 250px;
    height: 50px;
}
input{
    margin-bottom: 20px;
    height: 40px;
    width: 100%;
}
#add-invoice {
    height: 77vh;
    overflow: auto;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: solid rgb(207,219,226) 4px;
    border-radius: 5px;
    padding: 5%;
}
button{
  width: 120px; 
  margin: 5px;
}
#address-row{
  margin-bottom: 30px;
}

</style>