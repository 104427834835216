<template>
<div>
<div class="paymerang-logo"></div><br>
  <div id="invoice-info" class="container main-pane">
      <div class="row" id="return-button-one">
          <div class="col-12">
              <button @click="returnToPayee">Return</button>
          </div>
      </div><br>
      <div id="payor-personal-info" class="col-10">
          <div class="col-12">
            <span class="payee-data">Payor: <span id="payor-name">{{ payorName }}</span></span><br><br>
            <span class="payee-data">ID: <span class="payee-information" id="payor-ID-number">{{ payorID }}</span></span><br><br>
          </div>
          <div class="col-12">
            <span class="payee-data">Payee: <span id="payee-name">{{ payeeName }}</span></span><br><br>
          </div>
          <div class="col-12">
            <span class="payee-data">Invoice: <span class="payee-information" id="invoice-number">{{ invoiceNumber }}</span></span><br><br>
            <span class="payee-data">Description:<br><br> <span class="payee-information" id="invoice-description">{{ invoiceDescription }}</span></span><br><br>
            <span class="payee-data">Amount:<br><br> <span class="payee-information" id="invoice-amount">{{ invoiceAmount }}</span></span><br><br>
          </div>
      </div>
      <div id="payee-card-info" class="row">
          <button class="collapsible">Payment Information</button>
          <div class=" content col-12"><br>
              <span class="payee-data">PAN: <span class="payee-information" id="payee-card-pan">{{ cardPAN }}</span></span><br>
              <span class="payee-data">CVV: <span class="payee-information" id="payee-card-cvv">{{ cardCVV }}</span></span><br>
              <span class="payee-data">Exp: <span class="payee-information" id="payee-card-exp">{{ cardExp }}</span></span><br>
              <br>
          </div>
      </div>
      <div class="row" id="return-button-two">
          <div class="col-12">
              <button @click="returnToPayee">Return</button>
          </div>
      </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'InvoiceView',
  props: [
   "payeeObject",
   "invoiceObject",
  ],
  data() {
      return {
          payorName : "",
          payorID : "",
          payeeName : "",
          invoiceNumber : "",
          invoiceDescription : "",
          invoiceAmount : "",
          cardPAN : "",
          cardCVV : "",
          cardExp : "",
      }
  },
  mounted(){
      this.buildInvoiceInfo();

    //Allows for expansion and collapse of the payment information.
    //As we are dealing with card information, I would not want it to be immediately displayed upon loading the Payee information.
      var coll = document.getElementsByClassName("collapsible")
        for (var i = 0; i < coll.length; i++) {
        coll[i].addEventListener("click", function() {
            this.classList.toggle("active");
            var content = this.nextElementSibling;
            if (content.style.maxHeight){
            content.style.maxHeight = null;
            } else {
            content.style.maxHeight = content.scrollHeight + "px";
            } 
        });
    }
  },
  methods: {
      //This builds the Payee information as it is being passed in from app.vue
      buildInvoiceInfo() {
          this.payeeName = this.payeeObject["Payee"]["Name"];
          this.cardPAN = this.payeeObject["Payment"]["PAN"];
          this.cardCVV = this.payeeObject["Payment"]["CVV"];
          this.cardExp = this.payeeObject["Payment"]["Exp"];
          this.payorName = this.invoiceObject["PayorName"];
          this.payorID = this.invoiceObject["PayorId"];
          this.invoiceNumber = this.invoiceObject["InvoiceNo"];
          this.invoiceDescription = this.invoiceObject["Description"];
          this.invoiceAmount = this.invoiceObject["Amount"];
      },

      //Takes you back to the Payees list
      returnToPayee() {
          this.$emit('openPayee', this.payeeObject["Payee"]["Name"]);
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#invoice-info {
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: solid rgb(207,219,226) 4px;
    border-radius: 5px;
    padding: 2%;
    margin-bottom: 5%;
}
#address-pane{
    text-align: left;
}
#telecom-pane{
    text-align: left;
}
#attention-pane{
    margin-top: 2%;
}
#payee-personal-info, #payor-personal-info{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: solid rgb(207,219,226) 4px;
    border-radius: 5px;
    padding: 2%;
}
#payee-card-info{
    margin-left: auto;
    margin-right: auto;
    width: 65%;
    justify-content: center;
    margin-top: 2%;
    border: solid rgb(207,219,226) 4px;
    border-radius: 5px;
    padding: 1%;
}
#remittance-info{
    list-style: none;
    margin-left: auto;
    margin-right: auto;
    height: 300px;
    overflow: auto;
    width: 90%;
    justify-content: center;
    margin-top: 2%;
    border: solid rgb(207,219,226) 4px;
    border-radius: 5px;
    padding: 1%;
}
#return-button-two{
    margin-top: 2%;
}
#return-button-two{
    margin-bottom: 2%;
}
.collapsible {
  background-color: rgb(79,117,139);
  color: white;
  cursor: pointer;
  padding: 5px;
  width: 100%;
  border: none;
  text-align: center;
  outline: none;
  font-size: 15px;
  font-weight: bold;
}

.active, .collapsible:hover {
  background-color: AEBFC9;
}

.collapsible:after {
  content: '\002B';
  color: white;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.active:after {
  content: "\2212";
}

.content {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #f1f1f1;
}
</style>