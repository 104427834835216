import { createApp } from 'vue'
import App from './App.vue'
import firebase from 'firebase';
import "./assets/styles/global.css";

var firebaseConfig = {
    apiKey: "AIzaSyAYE7urpQzLw9sH-Ymj2-bFZENn69j82yk",
    authDomain: "remittance-vue.firebaseapp.com",
    projectId: "remittance-vue",
    storageBucket: "remittance-vue.appspot.com",
    messagingSenderId: "246964092277",
    appId: "1:246964092277:web:e190a180ecfc156a9d673c"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

createApp(App).mount('#app')
