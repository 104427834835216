<template>
<div>
  <div class="paymerang-logo"></div><br>
  <div id="landing" class="main-pane">
      <div id="landing-text" class="announcement">Payment Tracking System</div><br>
      <div class="announcement"><strong>By logging in you are stating that you belong here, and that you should, in fact, be working with this dataset.</strong></div><br>
      <div id="log-in">
      <span class="announcement">Enter your Email:</span><br><br>
      <input type="text" id="email-input"><br><br>
      <span class="announcement">Enter your passcode:</span><br><br>
      <input type="password" style="-webkit-text-security: square;" id="password-input"><br><br>
      <span id="error-text">{{ errorText }}</span>
      <button @click="acceptTerms" id="accept-button">Accept</button>
      </div> 
      <div id="loading"></div>
      
  </div>
</div>
</template>

<script>
export default {
  name: 'Landing',
  props: [
    "failedLogin",
  ],
  data() {
    return {
      errorText : "",
    }
  },
  watch: {
    failedLogin: function() { 
        this.loginFailed();
    }
  },
  mounted() {
    document.querySelector("#log-in").style.display = "block"
    document.querySelector("#loading").style.display = "none";
    document.querySelector("#error-text").style.display = "none";
  },
  methods: {
    //This fires the log-in request function in app.vue, and displays a loading icon for our more impatient users
      acceptTerms(){
          var email = document.getElementById("email-input").value;
          var password = document.getElementById("password-input").value;
          if (email != "" && password != ""){
            this.$emit('sendLoginRequest', email, password);
            document.querySelector("#log-in").style.display = "none"
            document.querySelector("#loading").style.display = "block";
          }
          else{
            this.errorText = "You must enter an email and password.";
            document.querySelector("#error-text").style.display = "block";
          }
      },
    //Displays "log in failed" span
      loginFailed() {
        if (this.failedLogin){
          document.querySelector("#log-in").style.display = "block"
          document.querySelector("#loading").style.display = "none";
          document.querySelector("#password-input").value = "";
          this.errorText = "Invalid email or password.";
          document.querySelector("#error-text").style.display = "block";
        }
      },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#error-text{
  color: red;
  font-weight: bold;
  margin-bottom: 10px;
  animation: shake 0.2s;
}
#landing-text{
  font-family: 'Nunito Sans', sans-serif;
  font-size: 36px;
  font-weight: bolder;
  background: linear-gradient(90deg, rgb(109,33,128), rgb(222,0,126));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#landing {
    height: 50%;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: solid rgb(207,219,226) 4px;
    border-radius: 5px;
    padding: 10%;
}
#loading{
  background-image: url("../../assets/images/loadinggif.gif");
  background-size: cover;
  background-repeat: no-repeat;
  width: 150px;
  height: 150px;
  display: none;
  margin-left: auto;
  margin-right: auto;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}
</style>
