<template>
<div>
<div class="paymerang-logo"></div><br>
  <div id="add-payee" class="container main-pane">
    <div class="col-12">
              <button @click="returnHome">Return</button>
  </div>
    <div id="name-row" class="row">
      <div id="add-payee-personal-info"><br>
        <input id="add-payee-data-name" placeholder="Payee Name"><br>
      </div>
    </div>
      <div id="address-row" class="row">
        <div id="telecom-pane" class="col-12">
            <br><input class="payee-information" id="add-payee-data-phone" placeholder="Phone Number"><br>
            <input class="payee-information" id="add-payee-data-fax" placeholder="Fax"><br>
            <input class="payee-information" id="add-payee-data-address-one" placeholder="Address 1"><br>
            <input class="payee-information" id="add-payee-data-address-two" placeholder="Address 2"><br>
            <input class="payee-information" id="add-payee-data-city" placeholder="City"><br>
            <input class="payee-information" id="add-payee-data-state" placeholder="State/Province"><br>
            <input class="payee-information" id="add-payee-data-country" placeholder="Country"><br>
            <input class="payee-information" id="add-payee-data-zip" placeholder="Postal Code"><br>
        </div>
    </div>
    <div class='row'>
        <div id="attention-pane" class="col-12">
            <input class="payee-information" id="add-attention" placeholder="Attention"><br>
            <input class="payee-information" id="add-submission-date" placeholder="Submission Date">
        </div>
      </div>
      <div id="payee-card-info" class="row">
          <div class="col-12"><br>
              <input class="payee-information" id="add-payee-card-pan" placeholder="PAN"><br>
              <input class="payee-information" id="add-payee-card-cvv" placeholder="CVV"><br>
              <input class="payee-information" id="add-payee-card-exp" placeholder="Exp"><br>
          </div>
      </div>
    <div class='row'>
      <div id="back-button-div" class="col-12">
        <button @click="collateNewPayee">Add Payee</button>
      </div>
    </div><br>
      <div class="col-12">
              <button @click="returnHome">Return</button>
  </div>
  </div><br>

</div>
</template>

<script>
export default {
  name: 'AddPayee',
  props: [
   
  ],
  data() {
    return {
      newPayee : 
      {
        "Payee": {
          "Name": "",
          "Fax": "",
          "Phone": "",
          "Address": {
            "Address1": "",
            "Address2": "",
            "City": "",
            "StateOrProvince": "",
            "Country": "",
            "PostalCode": "",
          },
          "Attention": "",
          "SubmissionDate": "",
        },
        "Payment": {
          "PAN": "",
          "CVV": "",
          "Exp": "",
        },
        "Remittance": [
          "",
        ],
      }
    }
  },
  mounted(){
      
  },
  methods: {
    collateNewPayee() {
      if (
          document.querySelector("#add-payee-data-name").value != "" && 
          document.querySelector("#add-payee-data-phone").value != "" && 
          document.querySelector("#add-payee-data-address-one".value && 
          document.querySelector("#add-payee-data-city").value != "" && 
          document.querySelector("#add-payee-data-state").value != "") != "" && 
          document.querySelector("#add-payee-data-country").value != ""&&
          document.querySelector("#add-payee-data-zip").value != "" && 
          document.querySelector("#add-attention").value != "" &&
          document.querySelector("#add-submission-date").value != "" &&
          document.querySelector("#add-payee-card-pan").value != "" &&
          document.querySelector("#add-payee-card-cvv").value != "" &&
          document.querySelector("#add-payee-card-exp").value != ""
        ){
          if (!document.querySelector("#add-payee-data-name").value == ""){ this.newPayee["Payee"]["Name"] = document.querySelector("#add-payee-data-name").value }
          if (!document.querySelector("#add-payee-data-fax").value == ""){ this.newPayee["Payee"]["Fax"] = document.querySelector("#add-payee-data-fax").value }
          if (!document.querySelector("#add-payee-data-phone").value == ""){ this.newPayee["Payee"]["Phone"] = document.querySelector("#add-payee-data-phone").value }
          if (!document.querySelector("#add-payee-data-address-one".value) == ""){ this.newPayee["Payee"]["Address"]["Address1"] = document.querySelector("#add-payee-data-address-one").value }
          if (!document.querySelector("#add-payee-data-address-two".value) == ""){ this.newPayee["Payee"]["Address"]["Address2"] = document.querySelector("#add-payee-data-address-two").value }
          if (!document.querySelector("#add-payee-data-city").value == ""){ this.newPayee["Payee"]["Address"]["City"] = document.querySelector("#add-payee-data-city").value }
          if (!document.querySelector("#add-payee-data-state").value == ""){ this.newPayee["Payee"]["Address"]["StateOrProvince"] = document.querySelector("#add-payee-data-state").value }
          if (!document.querySelector("#add-payee-data-country").value == ""){ this.newPayee["Payee"]["Address"]["Country"] = document.querySelector("#add-payee-data-country").value }
          if (!document.querySelector("#add-payee-data-zip").value == ""){ this.newPayee["Payee"]["Address"]["PostalCode"] = document.querySelector("#add-payee-data-zip").value }

          if (!document.querySelector("#add-attention").value == ""){ this.newPayee["Payee"]["Attention"] = document.querySelector("#add-attention").value }
          if (!document.querySelector("#add-submission-date").value == ""){ this.newPayee["Payee"]["SubmissionDate"] = document.querySelector("#add-submission-date").value }

          if (!document.querySelector("#add-payee-card-pan").value == ""){ this.newPayee["Payment"]["PAN"] = document.querySelector("#add-payee-card-pan").value }
          if (!document.querySelector("#add-payee-card-cvv").value == ""){ this.newPayee["Payment"]["CVV"] = document.querySelector("#add-payee-card-cvv").value }
          if (!document.querySelector("#add-payee-card-exp").value == ""){ this.newPayee["Payment"]["Exp"] = document.querySelector("#add-payee-card-exp").value }

          this.$emit('addPayee', this.newPayee);
        }
      else {
        alert("Name, Phone, Address (complete), Attention, Submission Date, and Card information is required to create.")
      }
    },
    returnHome() {
      this.$emit('openPage', 'payees');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#add-payee {
    height: 83vh;
    overflow: auto;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: solid rgb(207,219,226) 4px;
    border-radius: 5px;
    padding: 2%;
}
#add-payee-data-name{
  width: 200px;
  align-self: center;
}
#address-row{
  margin-bottom: 30px;
}
#back-button-div{
  margin-top: 30px;
}
button{
  width: 150px;
}
</style>
