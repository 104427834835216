<template>
<div>
<div class="paymerang-logo"></div><br>
  <div id="payee-info" class="container main-pane">
      <div class="row" id="return-button-one">
          <div class="col-12">
              <button @click="returnHome">Return</button>
          </div>
      </div><br>
      <div id="payee-personal-info" class="row">
        <span class="payee-data"><span id="payee-data-name">{{ payeeName }}</span></span><br><br>
        <div id="telecom-pane" class="col-6">
            <span class="payee-data">Phone Number:<br><span class="payee-information" id="payee-data-phone-number">{{ payeePhone }}</span></span><br><br>
            <span class="payee-data">Fax:<br><span class="payee-information" id="payee-data-fax">{{ payeeFax }}</span></span><br>
          </div>
        <div id="address-pane" class="col-6">
            <span class="payee-data">Address 1: <span class="payee-information" id="payee-data-address-one">{{ payeeAddressOne }}</span></span><br>
            <span class="payee-data">Address 2: <span class="payee-information" id="payee-data-address-two">{{ payeeAddressTwo }}</span></span><br>
            <span class="payee-data">City: <span class="payee-information" id="payee-data-city">{{ payeeCity }}</span></span><br>
            <span class="payee-data">State/Province: <span class="payee-information" id="payee-data-state">{{ payeeState }}</span></span><br>
            <span class="payee-data">Country: <span class="payee-information" id="payee-data-country">{{ payeeCountry }}</span></span><br>
            <span class="payee-data">Postal Code: <span class="payee-information" id="payee-data-zip">{{ payeeZip }}</span></span><br>
        </div>
        <div id="attention-pane" class="col-12">
            <span class="payee-data">Attention: <span class="payee-information" id="attention">{{ attention }}</span></span><br>
            <span class="payee-data">Submission Date: <span class="payee-information" id="submission-date">{{ submissionDate }}</span></span>
        </div>
      </div>
      <div id="payee-card-info" class="row">
          <button class="collapsible">Payment Information</button>
          <div class=" content col-12"><br>
              <span class="payee-data">PAN: <span class="payee-information" id="payee-card-pan">{{ cardPAN }}</span></span><br>
              <span class="payee-data">CVV: <span class="payee-information" id="payee-card-cvv"></span>{{ cardCVV }}</span><br>
              <span class="payee-data">Exp: <span class="payee-information" id="payee-card-exp"></span>{{ cardExp }}</span><br>
              <br>
          </div>
      </div>
      <div id="remittance-info" class="row">
          <ul @click="handleClickInvoice" id="remittance-list"></ul>
      </div>
      <div class="row" id="return-button-two">
          <div class="col-12">
              <button @click="returnHome">Return</button>
          </div>
      </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Payees',
  props: [
   "payeeObject",
  ],
  mounted(){
    this.buildPayeeInfo();
    //Allows for expansion and collapse of the payment information.
    //As we are dealing with card information, I would not want it to be immediately displayed upon loading the Payee information.
    var coll = document.getElementsByClassName("collapsible")
        for (var i = 0; i < coll.length; i++) {
        coll[i].addEventListener("click", function() {
            this.classList.toggle("active");
            var content = this.nextElementSibling;
            if (content.style.maxHeight){
            content.style.maxHeight = null;
            } else {
            content.style.maxHeight = content.scrollHeight + "px";
            } 
        });
    }
  },
  data() {
      return {
          payeeName : "",
          payeePhone : "",
          payeeFax : "",
          payeeAddressOne : "",
          payeeAddressTwo : "",
          payeeCity : "",
          payeeState : "",
          payeeCountry : "",
          payeeZip : "",
          attention : "",
          submissionDate : "",
          cardPAN : "",
          cardCVV : "",
          cardExp : "",
      }
  },
  methods: {
      //This builds the Payee information as it is being passed in from app.vue
      buildPayeeInfo() {
          this.payeeName = this.payeeObject["Payee"]["Name"];
          this.payeePhone = this.payeeObject["Payee"]["Phone"];
          this.payeeFax = this.payeeObject["Payee"]["Fax"];
          this.payeeAddressOne = this.payeeObject["Payee"]["Address"]["Address1"];
          this.payeeAddressTwo = this.payeeObject["Payee"]["Address"]["Address2"];
          this.payeeCity = this.payeeObject["Payee"]["Address"]["City"];
          this.payeeState = this.payeeObject["Payee"]["Address"]["StateOrProvince"];
          this.payeeCountry = this.payeeObject["Payee"]["Address"]["Country"];
          this.payeeZip = this.payeeObject["Payee"]["Address"]["PostalCode"];
          this.attention = this.payeeObject["Payee"]["Attention"];
          this.submissionDate = this.payeeObject["Payee"]["SubmissionDate"];
          this.cardPAN = this.payeeObject["Payment"]["PAN"];
          this.cardCVV = this.payeeObject["Payment"]["CVV"];
          this.cardExp = this.payeeObject["Payment"]["Exp"];
          this.buildRemittanceList();
      },
      //As remittances are not static, this builds them as list items so we have room for growth. Growth is good.
      buildRemittanceList() {
          document.querySelector("#remittance-list").innerHTML = "";
          for (var i=0; i<this.payeeObject["Remittance"].length; i++){
              if (this.payeeObject["Remittance"][i] != ""){
                  var remittanceItem = `
                    <li class="remittance-item"><br>
                        <span class="payor-name payee-data">Name: <span class="payee-information">${this.payeeObject["Remittance"][i]["PayorName"]}</span></span><br>
                        <span class="payor-id payee-data">ID: <span class="payee-information">${this.payeeObject["Remittance"][i]["PayorId"]}</span></span><br>
                        <span class="invoice-number payee-data">Invoice: <span class="payee-information">${this.payeeObject["Remittance"][i]["InvoiceNo"]}</span></span><br>
                        <span class="description payee-data">Description:<br><span class="payee-information">${this.payeeObject["Remittance"][i]["Description"]}</span></span><br>
                        <span class="amount payee-data">Amount: <span class="payee-information">${this.payeeObject["Remittance"][i]["Amount"]}</span></span><br><br>
                        <button value=${this.payeeObject["Remittance"][i]["InvoiceNo"]} class="open-invoice">Open</button><br><br>
                    </li><br>
                `
                document.querySelector("#remittance-list").innerHTML += remittanceItem;
              }
          }
      },
      //This handles click events within the List to fire a function that passes in both the Payee name and embedded invoice ID
      handleClickInvoice(e) {
          if (e.target.matches('.open-invoice')) {
            var invoiceID = e.target.value
            this.$emit('openInvoice', this.payeeObject["Payee"]["Name"], invoiceID)
        }
      },
      //Say goodnight, Kevin.
      //Takes you back to the Payees list
      returnHome() {
          this.$emit('openPage', 'payees');
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#payee-info {
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: solid rgb(207,219,226) 4px;
    border-radius: 5px;
    padding: 2%;
    margin-bottom: 5%;
}
#address-pane{
    text-align: left;
}
#telecom-pane{
    text-align: left;
}
#attention-pane{
    margin-top: 2%;
}
#payee-personal-info{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: solid rgb(207,219,226) 4px;
    border-radius: 5px;
    padding: 2%;
}
#payee-card-info{
    margin-left: auto;
    margin-right: auto;
    width: 65%;
    justify-content: center;
    margin-top: 2%;
    border: solid rgb(207,219,226) 4px;
    border-radius: 5px;
    padding: 1%;
}
#remittance-info{
    list-style: none;
    margin-left: auto;
    margin-right: auto;
    height: 300px;
    overflow: auto;
    width: 90%;
    justify-content: center;
    margin-top: 2%;
    border: solid rgb(207,219,226) 4px;
    border-radius: 5px;
    padding: 1%;
}
#return-button-two{
    margin-top: 2%;
}
#return-button-two{
    margin-bottom: 2%;
}
.collapsible {
  background-color: rgb(79,117,139);
  color: white;
  cursor: pointer;
  padding: 5px;
  width: 100%;
  border: none;
  text-align: center;
  outline: none;
  font-size: 15px;
  font-weight: bold;
}

.active, .collapsible:hover {
  background-color: AEBFC9;
}

.collapsible:after {
  content: '\002B';
  color: white;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.active:after {
  content: "\2212";
}

.content {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #f1f1f1;
}
</style>