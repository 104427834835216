<template>
<div>
<div class="paymerang-logo"></div><br>
<input v-model.lazy="search" id="search-bar" type="text" placeholder="Enter Payee, Payor, Payor ID, Amount or Invoice Number">
  <div id="payees" class="container main-pane">
    <ul @click="openPayee" id="payee-list"></ul>
  </div>
  <button @click="addPayee">Add Payee</button>
  <button @click="addInvoice">Add Invoice</button>
</div>
</template>

<script>
export default {
  name: 'Payees',
  props: [
   "payeeList",
  ],
  data() {
    return {
      listData : [

      ],
      search : "",
    }
  },
  mounted(){
      this.initialListLoad();
      this.buildPayeeList();
  },
  watch: {
            search: function (value) {
                this.searchFunction(value);
            }
        },
  methods: {
    //This loads the list initally, or resets the data after searching
      initialListLoad(){
        this.listData = this.payeeList;
      },
      //This allows the user to search for a Payee by partial name, partial payor name, payor ID, invoice number, or amount
      searchFunction(input){
        this.clearList();
        this.listData = [];
        if (input == ""){
          this.initialListLoad();
          this.buildPayeeList();
        }
        else{
          for (var s=0; s<this.payeeList.length; s++){
            if (this.payeeList[s]["Payee"]["Name"].toLowerCase().includes(input.toLowerCase())){
              this.listData.push(
                this.payeeList[s]
              )
            }
            for (var r=0; r<this.payeeList[s]["Remittance"].length; r++){
              if (this.payeeList[s]["Remittance"][r] != ""){
                if (input == this.payeeList[s]["Remittance"][r]["InvoiceNo"]){
                  this.listData.push(
                    this.payeeList[s]
                  )
                }
                if (input.replace(/\$|,/g, '') == this.payeeList[s]["Remittance"][r]["Amount"].replace(/\$|,/g, '')){
                  this.listData.push(
                    this.payeeList[s]
                  )
                }
                if (this.payeeList[s]["Remittance"][r]["PayorName"].toLowerCase().includes(input.toLowerCase())){
                  this.listData.push(
                    this.payeeList[s]
                  )
                }
                if (input == this.payeeList[s]["Remittance"][r]["PayorID"]){
                  this.listData.push(
                    this.payeeList[s]
                  )
                }
              }
          }
        }      
      }
        this.buildPayeeList();
      },
      //Clears the list on the DOM as needed
      clearList(){
        document.querySelector("#payee-list").innerHTML = "";
      },
      //This builds the list of Payees from the passed-in dataset, each as a list item to be displayed with appropriate information
      buildPayeeList() {
          for (var i=0; i<this.listData.length; i++){
              var payments = 0;
              var payeeTotal = 0.00;
                for (var j=0; j<this.listData[i]["Remittance"].length; j++){
                  if (this.listData[i]["Remittance"][j] != ""){
                    payments++
                    var paymentAmount = this.listData[i]["Remittance"][j]["Amount"].replace(/\$|,/g, '')
                    var parsedAmount = parseFloat(paymentAmount);
                    payeeTotal = payeeTotal + parsedAmount;
                  }
                }
                var payeeItem = `
                  <li class="payee-item">
                      <span class="payee-name payee-data">${this.listData[i]["Payee"]["Name"]}</span><br><br>
                      <span class="payee-payments payee-data">Payments: <span class="payee-information">${payments}</span></span><br><br>
                      <span class="payee-total payee-data">Total Paid: <span class="payee-information"> $${this.addCommas(payeeTotal)}</span> </span><br><br>
                      <button class="open-payee payee-data" value="${this.listData[i]["Payee"]["Name"]}" class="open-payee">Select</button>
                  </li><br>
                `;
                document.querySelector("#payee-list").innerHTML += payeeItem;
          }
      },
      //This allows me to use the attached id (in the form of a name) to fire the function in app.vue and pass back the name of the Payee to be displayed
      //It is more elegant than it looks, I assure you
      openPayee(e) {
          if (e.target.matches('.open-payee')) {
            var payeeID = e.target.value
            this.$emit('openPayee', payeeID);
      }
      },
      //This adds commas where they belong, as all things should be
      addCommas(x) {
            return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      //Opens the Add Payee page
      addPayee() {
        this.$emit('openPage','addPayee');
      },
      //Opens the Add Invoice page
      addInvoice() {
        this.$emit('openPage', 'addInvoice');
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#payees {
    height: 69vh;
    overflow: auto;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    justify-content: center;
    border: solid rgb(207,219,226) 4px;
    border-radius: 5px;
    padding: 5%;
}
button{
  width: 120px; 
  margin: 5px;
}
</style>
