<template>
  <div>
    <div v-if="landingPage">
      <Landing 
      :failedLogin="this.failedLogin"
        @sendLoginRequest="sendLoginRequest"
      />
    </div>
    <div v-if="payeePage">
      <Payees 
        :payeeList="this.passedData"
        @openPayee="openPayee"
        @openPage="openPage"
      />
    </div>
    <div v-if="payeeViewPage">
      <PayeeView 
        :payeeObject="this.currentItem"
        @openPage='openPage'
        @openInvoice="openInvoice"
      />
    </div>
    <div v-if="addPayeePage">
      <AddPayee 
        @addPayee="addPayee"
        @openPage="openPage"
      />
    </div>
    <div v-if="addInvoicePage">
      <AddInvoice 
        :passedData="this.passedData"
        @addInvoice="addInvoice"
        @openPage="openPage"
      />
    </div>
    <div v-if="invoiceViewPage">
      <InvoiceView
        :invoiceObject="this.currentInvoiceObject"
        :payeeObject="this.currentPayeeObject"
        @openPayee="openPayee"
      />
    </div>
  </div>
</template>

<script>
//Import Firebase
import firebase from 'firebase';
//Import components
import Landing from './components/Main Components/Landing.vue';
import Payees from "./components/Main Components/Payees.vue";
import PayeeView from "./components/Main Components/PayeeView.vue";
import AddPayee from "./components/Main Components/AddPayee.vue";
import AddInvoice from "./components/Main Components/AddInvoice.vue";
import InvoiceView from "./components/Main Components/InvoiceView.vue";

export default {
  name: 'App',
  components: {
    Landing,
    Payees,
    PayeeView,
    AddPayee,
    AddInvoice,
    InvoiceView,
  },
  data() {
    return {
      passedData : [],

      landingPage : false,
      payeePage : false,
      payeeViewPage : false,
      addPayeePage : false,
      addInvoicePage : false,
      invoiceViewPage : false,

      failedLogin : false,

      currentItem : {},
      currentInvoiceObject : {},
      currentPayeeObject : {},
      currentPayeeName :  "",

      currentUser : "",
    }
  },
  mounted() {
    this.openPage("landing");
  },
  methods: {
    //As I cannot imagine a reason we would ever expose payment data, it is stored in a database, and is accessed by email/password authentication
    //Firing as an asyc/wait because to do otherwise would be to invite disaster and/or ruination
    async sendLoginRequest(email, password){
      await firebase.auth().signInWithEmailAndPassword(email, password).then(
        user => {
          var serverUserData = firebase.database().ref('users/' + user.user.uid);
          serverUserData.on('value', (snapshot) => {
          const data = snapshot.val();
          this.failedLogin = false;
          this.currentUser = user.user.uid;
          this.passedData = data.data;
          this.openPage('payees');
          });
        },
        err => {
          console.log(err)
          if(err.code == "auth/wrong-password" || err.code == "auth/user-not-found"){
            console.log("Login failed")
            this.failedLogin = true;
          }
        }
        )
    },
    //This allows for updating of the server-side data
    updateServerData() {
      if (this.currentUser != ""){
        firebase.database().ref('users/' + this.currentUser).update({
          data : this.passedData
        })
      }
        else{
          console.log(this.currentUser + "not found")
        }
    },
    //This does the switching between panes, keeping the entire program a true single page application
    //I don't want data to persist past refresh for security reasons, so I am avoiding Vue Router- however, it is also an option here, if you want reusable endpoints.
    openPage(page) {
      this.landingPage = false;
      this.payeePage = false;
      this.payeeViewPage = false;
      this.addPayeePage = false;
      this.addInvoicePage = false;
      this.invoiceViewPage = false;

      switch (page){
        case "landing":
          this.landingPage = true;
          break;
        case "payees":
          this.payeePage = true;
          break;
        case "payeeView":
          this.payeeViewPage = true;
          break;
        case "addPayee":
          this.addPayeePage = true;
          break
        case "addInvoice":
          this.addInvoicePage = true;
          break;
        case "invoiceView":
          this.invoiceViewPage = true;
          break;
        default:
          break;
      }
    },
    //This allows the user to open individual invoices for easier printing of the data therein.
    openInvoice(payee, invoiceNumber) {
      for (var s=0; s<this.passedData.length; s++){
          if (this.passedData[s]["Payee"]["Name"] == payee){
            this.currentPayeeName = this.passedData[s]["Payee"]["Name"]
              this.currentPayeeObject = this.passedData[s]
              for (var r=0; r<this.passedData[s]["Remittance"].length; r++){
                if (invoiceNumber == this.passedData[s]["Remittance"][r]["InvoiceNo"]){        
                    this.currentInvoiceObject = this.passedData[s]["Remittance"][r];
                }
              }                
            }
          }
        this.openPage("invoiceView");
    },
    //This calls the retrieval function to grab the requested Payee from the data being served from the database.
    //Small, but mighty.
    openPayee(name){
      this.currentItem = {}
      this.retrieveFromDatasheet(name);
      this.openPage('payeeView')
    },
    //This accepts the data object from the AddPayee component and adds it to the server data object, which it then uploads to the server via updateServerData.
    addPayee(newPayee) {
      this.passedData.push(newPayee);
      this.updateServerData();
    },
    //This accepts an object from AddInvoice and a Payee name, then adds said object to the Payee's remittance array. It then updates server data.
    addInvoice(invoice, payee) {
      for (var t=0; t<this.passedData.length; t++){
        if (this.passedData[t]["Payee"]["Name"] == payee){
          this.passedData[t]["Remittance"].push(invoice);
        }
      }
      this.updateServerData();
      this.openPage('payees');
    },
    //This grabs the data for the requested Payee by their name. In a perfect world, I would want a unique ID rather than a name, but we work with what we've got
    retrieveFromDatasheet(name){
      for (var i=0 ; i < this.passedData.length ; i++)
          {
              if (this.passedData[i]["Payee"]["Name"] == name) {
                  //bounce the item to "global" so that the calling functions can actually see it
                  this.currentItem = this.passedData[i];
              }
          }
    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
